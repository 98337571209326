import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

import { Buffer } from "buffer";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

import { AdapterService } from './adapter.service';
import { AuthenModel, SignInModel } from '@shared/models';

@Injectable()
export class AuthenService {
  private api: string = 'auth';

  constructor(
    private router: Router,
    private adapter: AdapterService
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public signIn(data: SignInModel): Promise<boolean> {
    return this.adapter.postData(`${this.api}/signin`, data).toPromise()
      .then((ret: AuthenModel) => {
        let token = Buffer.from(ret.token).toString('base64');
        sessionStorage.setItem('akey', token);
        let renew = Buffer.from(ret.refresh_token).toString('base64');
        sessionStorage.setItem('rkey', renew);
        let urole = ret.role.toLowerCase();
        sessionStorage.setItem('role', urole);
        let state = ret.status.toLowerCase();
        sessionStorage.setItem('type', state);
        // set access token to system
        this.adapter.accessToken = ret.token;
        return true;
      }).catch(error => {
        console.error(error);
        sessionStorage.clear();
        return false;
      });
  }

  public getAccessToken() {
    let token = sessionStorage.getItem('akey');
    let isExist = !isEmpty(token);
    return { isExist, token };
  }

  public signOut(): void {
    sessionStorage.clear();
    this.adapter.accessToken = "";
  }

  public isAuthorized(): boolean {
    if (isEmpty(this.adapter.accessToken)) {
      let utype = sessionStorage.getItem('type');
      if (isEmpty(utype))
        return false;
      let token = sessionStorage.getItem('akey');
      if (isEmpty(token))
        return false;
      // set access token to system
      this.adapter.accessToken = Buffer.from(token, 'base64').toString();
      // check the token expire
      let helper = new JwtHelperService();
      if (helper.isTokenExpired(this.adapter.accessToken)) {
        console.warn('Token is expired');
        return false;
      }
      // check the user status
      if (sessionStorage.getItem('type') === "inactive") {
        console.warn('User status is inactive');
        return false;
      }
    }
    return true;
  }

  public isAdmin(): boolean {
    let role = sessionStorage.getItem('role');
    return (role == 'admin');
  }
}
