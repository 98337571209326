import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

import { AdapterService } from './adapter.service';

@Injectable()
export class ReturnService {
  private api: string = 'roi';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public getList(): Observable<any> {
    return this.adapter.getData(`${this.api}/list`).pipe(map((ret) => ret));
  }

  public getGraph(body: any): Observable<any> {
    return this.adapter.postData(`${this.api}/graph`, body).pipe(map((ret) => ret));
  }

  public getPeriod(body: any): Observable<any> {
    return this.adapter.postData(`${this.api}/period`, body).pipe(map((ret) => ret));
  }

  public getStatementAvailable():Observable<any>{
    return this.adapter.getData(`${this.api}/statement-list`).pipe(map((ret) => ret));
  }

  public requestStatement(body): Observable<any>{
    return this.adapter.postData(`${this.api}/statement-request`,body).pipe(map((ret) => ret));
  }
}
