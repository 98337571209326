import { NgModule } from '@angular/core';
import {
  APP_BASE_HREF,
  LocationStrategy,
  Location,
  PathLocationStrategy,
  HashLocationStrategy,
  registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@env';

import { AuthenGuard } from '@shared/guards';
import { TranslatorPipe } from '@shared/pipes';
import {
  AdapterService,
  AccountService,
  AlertService,
  AuthenService,
  BalanceService,
  CipherService,
  CommonService,
  ConfigService,
  CustomerService,
  InvestorService,
  MessagingService,
  OfferService,
  PortfolioService,
  ReturnService,
  RootService,
  StorageService,
  SummaryService,
  SuitabilityService,
  PaymentService
} from '@shared/services';

import { AppComponent } from './app.component';
import { AppInterceptor } from './app.interceptor';

import localeTh from '@angular/common/locales/th';
registerLocaleData(localeTh, 'th');

const BASE_ROUTES: Routes = [{
  path: '', redirectTo: 'signin', pathMatch: 'full'
}, {
  path: 'void', component: AppComponent
}, {
  path: 'console',
  loadChildren: () => import('./pages/console/console.module').then(m => m.ConsoleModule)
}, {
  path: 'register',
  loadChildren: () => import('./pages/setpwd/setpwd.module').then(m => m.SetPwdModule)
}, {
  path: 'setpwd',
  loadChildren: () => import('./pages/setpwd/setpwd.module').then(m => m.SetPwdModule)
}, {
  path: 'signin',
  loadChildren: () => import('./pages/signin/signin.module').then(m => m.SignInModule)
}, {
  path: 'signup',
  loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
}, {
  path: '404',
  loadChildren: () => import('./pages/404/404.module').then(m => m.NotFoundModule)
}, {
  path: '**', redirectTo: '404'
}];

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    // Note: remove hash to useHash is false or not include
    RouterModule.forRoot(BASE_ROUTES, { useHash: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    NgbModule
  ],
  providers: [
    Location,
    { provide: APP_BASE_HREF, useValue: '/' },
    // Note: remove hash to useClass is PathLocationStrategy
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthenGuard,
    AccountService,
    AdapterService,
    AlertService,
    AuthenService,
    BalanceService,
    CipherService,
    CommonService,
    ConfigService,
    CustomerService,
    InvestorService,
    MessagingService,
    OfferService,
    PortfolioService,
    ReturnService,
    RootService,
    StorageService,
    SummaryService,
    SuitabilityService,
    PaymentService
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
