import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdapterService } from './adapter.service';

@Injectable()
export class OfferService {
  private api: string = 'offer';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public getAllOffers(type = null): Observable<any> {
    let data = (type == null) ? "" : `?status=${type}`;
    return this.adapter.getData(`${this.api}/list${data}`).pipe(map((ret) => (ret)));
  }

  public setGrant(id, type: string): Observable<any> {
    let data = { id: id, type: type };
    return this.adapter.putData(`${this.api}/grant`, data).pipe(map((ret) => ret));
  }

  public getLoanInformation(id: string): Observable<any> {
    return this.adapter.getData(`${this.api}/contract?rid=${id}`).pipe(map((ret) => ret));
  }

  public countPending(): Observable<any>{
    return this.adapter.getData(`${this.api}/count-pending`).pipe(map((ret) => ret));
  }
}