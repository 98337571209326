export const environment = {
  production: true,
  version: '1.0.0',
  urlHome: 'https://chessmoney.co.th',
  api: {
    env: 'product',
    url: 'https://asia-southeast2-lenderportal-prd.cloudfunctions.net',
    key: 'oPFYQLtJktJ3102nT73j',
    fcm: "BIKg-a2BfApqjJdckxIkPUpHCyb-TD9SsNCg8ub3Qox8d18FEXMwoGEZkk71IcHHJI6ejhJIsRnS0xNpTAYpgP0"
  },
  firebase: {
    apiKey: "AIzaSyCdXZDOCM8wtYoxb58Cc-86ZzSUn3SOoLQ",
    authDomain: "lenderportal-prd.firebaseapp.com",
    databaseURL: "https://lenderportal-prd.firebaseio.com",
    projectId: "lenderportal-prd",
    storageBucket: "lenderportal-prd.appspot.com",
    messagingSenderId: "577197968557",
    appId: "1:577197968557:web:6445c60fab8bf82def8a24",
    measurementId: "G-5X0R0YQW0V"
  }
};
