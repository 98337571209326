import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

import { AdapterService } from './adapter.service';
import { SummaryBalanceModel, TotalReturnModel, InvestmentReturnModel, CreditRiskModel } from '@shared/models';

@Injectable()
export class SummaryService {
  private api: string = 'summary';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  getSumBalance(): Observable<SummaryBalanceModel> {
    return this.adapter.getData(`${this.api}/balance`).pipe(map((ret) => ret, (err) => err));
  }

  getSumReturn(): Observable<TotalReturnModel> {
    return this.adapter.getData(`${this.api}/roi`).pipe(map((ret) => ret));
  }

  getInvestmentReturn(): Observable<InvestmentReturnModel> {
    return this.adapter.getData(`${this.api}/invt-return`).pipe(map((ret) => ret));
  }

  getCreditRisk(): Observable<CreditRiskModel> {
    return this.adapter.getData(`${this.api}/credit-risk`).pipe(map((ret) => ret));
  }
}