import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenService } from '@shared/services';

@Injectable()
export class AuthenGuard {
  constructor(
    private router: Router,
    private authen: AuthenService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAuth = this.authen.isAuthorized();
    if (isAuth === false) {
      this.authen.signOut();
      this.router.navigate(['/signin']);
    }
    let status = sessionStorage.getItem('type');
    if (status === "pending" || status === "verify")
      this.router.navigate(['/signup']);
    return true;
  }
}
