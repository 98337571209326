import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdapterService } from './adapter.service';
import * as XLSX from 'xlsx';

@Injectable()
export class CustomerService {
  private api: string = 'customer';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public getCustomerInfo(relation: string): Observable<any> {
    return this.adapter.getData(`${this.api}/loan?rid=${relation}`).pipe(map((ret) => ret, (err) => err));
  }

  public createPDF(value: any, fileName: string, type: string) {
    let ws: XLSX.WorkSheet;
    if (type == 'from-table') {
      /* create work sheet from table-id */ 
      let element = document.getElementById(value);
      ws = XLSX.utils.table_to_sheet(element);
    } else {
      /* create work sheet from json */
      ws = XLSX.utils.json_to_sheet(value);
    }
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */  
    XLSX.writeFile(wb, fileName);
  }
}