import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdapterService } from './adapter.service';

@Injectable()
export class BalanceService {
  private api: string = 'balance';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public getAllBalance(): Observable<any> {
    return this.adapter.getData(`${this.api}/list`).pipe(map((ret) => ret));
  }

  public getBalncByType(type: string): Observable<any> {
    return this.adapter.getData(`${this.api}/list?type=${type}`).pipe(map((ret) => ret, (err) => err));
  }

  public setTransaction(data: any): Observable<any> {
    return this.adapter.postData(`${this.api}/tnx`, data).pipe(map((ret) => ret));
  }

  public setMatchingRule(data: any): Observable<any> {
    return this.adapter.postData(`${this.api}/set-rule`, data).pipe(map((ret) => ret));
  }

  public getMatchingRule(): Observable<any> {
    return this.adapter.getData(`${this.api}/get-rule`).pipe(map((ret) => ret));
  }

  public getLastBalance(): Observable<any> {
    return this.adapter.getData(`${this.api}/last`).pipe(map((ret) => ret));
  }

  public getIsWithdrawal(): Observable<any> {
    return this.adapter.getData(`${this.api}/is-withdrawal`).pipe(map((ret) => ret));
  }

}