import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
  title = 'Lender Portal Application';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService
  ) {
    // let locale = localStorage.getItem('locale') || 'en';
    let locale = localStorage.getItem('locale') || 'th';
    this.document.documentElement.lang = locale;
    this.translate.use(locale);
  }

  ngOnInit() {
    this.translate.addLangs(['en', 'th']);
    // this.translate.setDefaultLang('en');
    this.translate.setDefaultLang('th');

  }
}
