import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

import { AdapterService } from './adapter.service';


@Injectable()
export class PortfolioService {
  private api: string = 'portfolio';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public getList(): Observable<any> {
    return this.adapter.getData(`${this.api}/list`).pipe(map((ret) => ret));
  }

  public getPeriod(dateSet: object): Observable<any> {
    return this.adapter.postData(`${this.api}/period`, dateSet).pipe(map((ret) =>  ret));
  }
}