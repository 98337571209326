import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdapterService } from './adapter.service';

@Injectable()
export class PaymentService {
  private api: string = 'payment';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public getPrepaid(start,end): Observable<any> {
    return this.adapter.getData(`${this.api}/prepay?start=${start}&end=${end}`).pipe(map((ret) => ret));
  }

  public getDirectDebitComp(start,end): Observable<any>{
    return this.adapter.getData(`${this.api}/directdebit-complete?start=${start}&end=${end}`).pipe(map((ret) => ret));
  }
  
  public getDirectDebitPending(): Observable<any> {
    return this.adapter.getData(`${this.api}/directdebit-pending`).pipe(map((ret) => ret));
  }

  public getManualOverdue(start,end): Observable<any> {
    return this.adapter.getData(`${this.api}/manual-overdue?start=${start}&end=${end}`).pipe(map((ret) => ret));
  }

  public getManualComplete(start,end): Observable<any> {
    return this.adapter.getData(`${this.api}/manual-complete?start=${start}&end=${end}`).pipe(map((ret) => ret));
  }

  public getManuelPending(start,end): Observable<any> {
    return this.adapter.getData(`${this.api}/manual-pending?start=${start}&end=${end}`).pipe(map((ret) => ret));
  }


}