export class AlertData {
  id: string;
  type: AlertType;
  message: any;
  fade: boolean;

  constructor(init?:Partial<AlertData>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  NONE,
  SUCCESS,
  ERROR,
  INFO,
  WARNING
}