import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AlertData, AlertType } from '@shared/models';

@Injectable()
export class AlertService {
  private subject = new BehaviorSubject(null);
  private default = 'alert-default';

  // enable subscribing to alerts observable
  onAlert(id = this.default): Observable<AlertData> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  // main alert method  
  show(alert: AlertData) {
    alert.id = alert.id || this.default;
    this.subject.next(alert);
  }

  // clear alerts
  clear(id = this.default) {
    this.subject.next(new AlertData({ id }));
  }
}