import { Injectable } from "@angular/core";
import { Buffer } from "buffer";
import * as Crypt from "crypto-js";  

@Injectable()
export class CipherService {
  constructor() {
    //
  }

  encryption(key, data) {
    var encode = Crypt.AES.encrypt(data, key).toString();
    return encode;
  }

  decryption(key, data) {
    var raw = Buffer.from(data, 'base64');
    var ivd = raw.slice(0, 16).toString('hex');
    var msg = raw.slice(16).toString('hex');
    var pwd = Buffer.from(key, 'utf8').toString('hex');

    var decode = Crypt.AES.decrypt(msg, pwd, {
      iv: Crypt.enc.Hex.parse(ivd),
      mode: Crypt.mode.CTR,
      padding: Crypt.pad.NoPadding
    }).toString(Crypt.enc.Hex);
    return Buffer.from(decode, 'hex').toString();
  }
}