import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isEmpty } from 'lodash-es';

import { AdapterService } from './adapter.service';
import { NoticeListModel, MemberProfile } from '@shared/models';

@Injectable()
export class InvestorService {
  private api: string = 'investor';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public getAllMember(): Observable<Array<MemberProfile>> {
    return this.adapter.getData(`${this.api}/all-member`).pipe(map((ret) => ret));
  }

  public addMember(profile: any): Observable<string> {
    return this.adapter.postData(`${this.api}/add-member`, profile).pipe(map((ret) => ret));
  }

  public deleteMember(profile: any): Observable<any> {
    return this.adapter.deleteData(`${this.api}/del-member?email=${profile.email}`).pipe(map((ret) => ret))
  }

  public getOrgContact(): Observable<any> {
    return this.adapter.getData(`${this.api}/get`).pipe(map((ret) => ret));
  }

  public updateOrgContact(contact: any): Observable<any> {
    return this.adapter.postData(`${this.api}/update`, contact).pipe(map((ret) => ret));
  }

  public getMatchingStatus(): Observable<any> {
    return this.adapter.getData(`${this.api}/enable-matching`).pipe(map((ret) => ret));
  }

  public updateMatchingStatus(enable: any): Observable<any> {
    return this.adapter.postData(`${this.api}/update-matching`, enable).pipe(map((ret) => ret));
  }

  public getListNotice(): Observable<NoticeListModel> {
    return this.adapter.getData(`${this.api}/notice-list`).pipe(map((ret) => ret, (err) => err));
  }

  public setReadNotice(): Observable<NoticeListModel> {
    return this.adapter.putData(`${this.api}/notice-read`, null).pipe(map((ret) => ret));
  }

  public reportIssue(data): Promise<boolean> {
    return this.adapter.postData(`${this.api}/create-issue`, data).toPromise()
      .then((ret) => {
        return true;
      }).catch(error => {
        console.error(error);
        return false;
      });
  }

  public getIssue(): Observable<any> {
    return this.adapter.getData(`${this.api}/get-issue`).pipe(map((ret) => {
      return (ret != null) ? ret.values : null;
    }));
  }
}