import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdapterService } from './adapter.service';

@Injectable()
export class SuitabilityService {
  private api: string = 'suitability';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }


  public get(): Observable<any> {
    return this.adapter.getData(`${this.api}/get`).pipe(map((ret) => ret));
  }

  public update(data : any): Observable<any> {
    return this.adapter.postData(`${this.api}/update`, data).pipe(map((ret) => ret));
  }

}