import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { environment } from '@env';

import { AccountService } from './account.service';

@Injectable()
export class MessagingService {
  private subject = new BehaviorSubject(null);
  private service = getMessaging();

  constructor(
    private account: AccountService,
  ) { }
  
  public getPermission() {
    getToken(this.service, { vapidKey: environment.api.fcm }).then(token => {
      this.account.setFcmToken(token).subscribe();
    }).catch(err => console.error(err));
  }

  public listenMessage() {
    onMessage(this.service, (payload) => this.subject.next(payload));
  }

  public getMessage(): Observable<any> {
    return this.subject.asObservable().pipe(ret => ret);
  }
}