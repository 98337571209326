import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import JSZip from 'jszip';
import { Observable, from } from 'rxjs';
import { environment } from '@env';

@Injectable()
export class StorageService {

  constructor(
    private http: HttpClient,
    private storage: AngularFireStorage
  ) { }

  // On upload the file
  public upload(path: string, file: any): Observable<number> {
    let task = this.storage.upload(path, file);
    return task.percentageChanges();
  }

  // On download the file
  public download(path: string): Observable<any> {
    let task = this.storage.ref(path);
    return task.getDownloadURL();
  }

  // On download and save the file
  public saveFile(path: string, name: string) {
    let task = this.storage.ref(path);
    from(task.getDownloadURL()).subscribe((ret :string) => {
      this.http.get(ret, {responseType: 'blob'}).subscribe(obj => {
        let url = URL.createObjectURL(obj);
        let elm = document.createElement('a');
        elm.href = url;
        elm.download = name;
        elm.click();
        window.URL.revokeObjectURL(url);
        elm.remove();
      });
    });
  }

  // On download and zip the files
  public compress(data: {name: string; path: string}[], name: string) {
    let zip = new JSZip();
    Promise.all(data.map(idx => {
      // download from path list
      let urn = new URL(idx.path).pathname;
      return new Promise((resolve) => {
        let path = urn.replace(`/${environment.firebase.storageBucket}`, "");
        let task = this.storage.ref(path);
        from(task.getDownloadURL()).subscribe((ret: string) => {
          this.http.get(ret, {responseType: 'arraybuffer'}).subscribe(obj => resolve(zip.file(idx.name, obj)));
        });
      });
    })).finally(() => {
      // compress the files
      zip.generateAsync({type: "blob"}).then(obj => {
        let url = URL.createObjectURL(obj);
        let elm = document.createElement('a');
        elm.href = url;
        elm.download = name;
        elm.click();
        window.URL.revokeObjectURL(url);
        elm.remove();
      });
    });
  }
}
