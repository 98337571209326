import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Buffer } from "buffer";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdapterService } from './adapter.service';
import {
  AuthenModel,
  RegisterModel,
  SignUpLenderModel,
  SignUpVerifyModel
} from '@shared/models';

@Injectable()
export class RootService {
  private api: string = 'root';

  constructor(
    private router: Router,
    private adapter: AdapterService
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public register(data: RegisterModel): Promise<boolean> {
    return this.adapter.postData(`${this.api}/register`, data).toPromise()
      .then((ret: AuthenModel) => {
        let token = Buffer.from(ret.token, 'utf8').toString('base64');
        sessionStorage.setItem('akey', token);
        let renew = Buffer.from(ret.refresh_token, 'utf8').toString('base64');
        sessionStorage.setItem('rkey', renew);
        let urole = ret.role.toLowerCase();
        sessionStorage.setItem('role', urole);
        let state = ret.status.toLowerCase();
        sessionStorage.setItem('type', state);
        // set access token to system
        this.adapter.accessToken = ret.token;
        return true;
      }).catch(err => err);
  }

  public signupVerify(data: SignUpVerifyModel): Observable<any> {
    return this.adapter.postData(`${this.api}/signup-verify`, data).pipe(map((ret) => ret, (err) => err));
  }

  public signupLender(data: SignUpLenderModel): Observable<any> {
    return this.adapter.postData(`${this.api}/signup-lender`, data).pipe(map((ret) => ret, (err) => err));
  }
}
