import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdapterService } from './adapter.service';
import { ConsentModel } from '@shared/models';

@Injectable()
export class AccountService {
  private api: string = 'account';

  constructor(
    private router: Router,
    private adapter: AdapterService,
  ) { }

  public getIam(): Observable<string> {
    return this.adapter.getData(`${this.api}/iam`).pipe(map((ret) => ret));
  }

  public getInfo(): Observable<string> {
    return this.adapter.getData(`${this.api}/get`).pipe(map((ret) => ret));
  }

  public setProfile(profile: any): Observable<string> {
    return this.adapter.postData(`${this.api}/set-profile`, profile).pipe(map((ret) => ret));
  }

  public setFcmToken(token: string): Observable<string> {
    let data = { fcm_token: token };
    return this.adapter.putData(`${this.api}/set-fcm`, data).pipe(map((ret) => ret));
  }

  public getProfile(): Observable<any> {
    return this.adapter.getData(`${this.api}/get-profile`).pipe(map((ret) => ret));
  }

  public changePassword(data: any): Observable<string> {
    return this.adapter.postData(`${this.api}/pwd-change`, data).pipe(map((ret) => ret));
  }

  public forgotPassword(data: any): Observable<string> {
    return this.adapter.postData(`${this.api}/pwd-forgot`, data).pipe(map((ret) => ret, (err) => err));
  }

  public setNewPassword(data: any): Observable<string> {
    return this.adapter.postData(`${this.api}/pwd-setnew`, data).pipe(map((ret) => ret, (err) => err));
  }

  public getConsent(): Observable<ConsentModel> {
    return this.adapter.getData(`${this.api}/get-consent`).pipe(map((ret) => ret, (err) => err));
  }

  public setConsent(data: ConsentModel): Observable<any> {
    return this.adapter.postData(`${this.api}/set-consent`, data).pipe(map((ret) => ret));
  }
}