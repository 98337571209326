import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { get, isEmpty } from 'lodash-es';
import { throwError } from 'rxjs';

@Injectable()
export class ConfigService {
  private base = './assets';
  private config = null;

  constructor(private http: HttpClient) { }

  private handleError(err: any) {
    return throwError(err.error || 'Error on configuration');
  }
  // On load the configuration
  public load() {
    const result = this.http.get(`${this.base}/config.json`).toPromise().then(data => {
      if (isEmpty(data)) {
        console.error('Error to get config with api service');
        return;
      }
      this.config = data;
    }, this.handleError);
    return result;
  }
  // On get config data
  public getConfig(param: string = null): any {
    return isEmpty(param) ? this.config : get(this.config, param, []);
  }
}
