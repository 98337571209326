
export const BASE_MENU: Object = [ ];

export class SubMenuModel {
  code: string;
  info: string;
  path: string;
  active: boolean;
  select: boolean;
  constructor() {
    this.active = false;
    this.select = false;
  }
}

export class MenuModel {
  code: string;
  info: string;
  path: string;
  active: boolean;
  select: boolean;
  child?: (SubMenuModel)[] | null;
  constructor() {
    this.active = false;
    this.select = false;
    this.info = "Dashboard";
    this.child = null;
  }
}

export interface ResponseModel {
  status:  boolean,
  code:    number,
  message: string,
  data:    object,
}

export class MonthModel {
  months = [
    { view: "Jan", value: 0 },
    { view: "Feb", value: 1 },
    { view: "Mar", value: 2 },
    { view: "Apr", value: 3 },
    { view: "May", value: 4 },
    { view: "Jun", value: 5 },
    { view: "Jul", value: 6 },
    { view: "Aug", value: 7 },
    { view: "Sep", value: 8 },
    { view: "Oct", value: 9 },
    { view: "Nov", value: 10 },
    { view: "Dec", value: 11 },
  ];
  monthly = [
    { value: "monthly", view: "Monthly" },
    { value: "quarterly", view: "Quarterly" },
    { value: "half-yearly", view: "Half-yearly" },
    { value: "annually", view: "Annualy" }
  ];
  constructor() {
  }
}

export class BankListModel {
  bank = [
    { view: "Bangkok Bank Public Company Limited", value: "BBL" },
    { view: "Kasikornbank Public CompanyLimited", value: "KBANK" },
    { view: "The Royal Bank of Scotland N.V.", value: "RBS" },
    { view: "Krung Thai Bank Public Company Limited", value: "KTB" },
    { view: "JP Morgan Chase Bank", value: "JPM" },
    { view: "TTB Bank", value: "TMB" },
    { view: "Siam Commercial Bank Public Company Limited", value: "SCB" },
    { view: "Citibank N.A.", value: "CITI" },
    { view: "Sumitomo Mitsui Banking Corporation", value: "SMBC" },
    { view: "Standard Chartered Bank (Thai) Public Company Limited", value: "SC" },
    { view: "CIMB Thai Bank Public Company Limited", value: "CIMB" },
    { view: "United Overseas Bank (Thai) PCL", value: "UOB" },
    { view: "Bank of Ayudhya Public Company Limited", value: "BAY" },
    { view: "Mega International Commercial Bank PCL", value: "MEGA" },
    { view: "Bank of America National Association", value: "BOA" },
    { view: "Government Saving Bank", value: "GSB" },
    { view: "Hong Kong & Shanghai Corporation Limited", value: "HSBC" },
    { view: "Deutsche Bank Aktiengesellschaft", value: "DB" },
    { view: "Government Housing Bank", value: "GHB" },
    { view: "Bank for Agriculture and Agricultural Cooperatives", value: "BAAC" },
    { view: "Mizuho Corporate Bank Limited", value: "MB" },
    { view: "BNP Paribas Bank", value: "BNP" },
    { view: "Bank of China ( Thai ) Public Company Limited", value: "BOC" },
    { view: "Islamic Bank of Thailand", value: "IBANK" },
    { view: "Tisco Bank Public Company Limited", value: "TISCO" },
    { view: "Kiatnakin Bank Public Company Limited", value: "KK" },
    { view: "ICBC Thai Commercial Bank", value: "ICBC" },
    { view: "The Thai Credit Retail Bank Public Company Limited", value: "TCRB" },
    { view: "Land and Houses Bank Public Company Limited", value: "LHB" },
    { view: "ANZBank(Thai)PublicCompanyLimited", value: "ANZ" },
  ];
}

export class monthOption{
  jan: boolean;
  feb: boolean;
  mar: boolean;
  apr: boolean;
  may: boolean;
  jun: boolean;
  jul: boolean;
  aug: boolean;
  sep: boolean;
  oct: boolean;
  nov: boolean;
  dec: boolean;

  constructor() {
    this.jan = false;
    this.feb = false;
    this.mar = false;
    this.apr = false;
    this.may = false;
    this.jun = false;
    this.jul = false;
    this.aug = false;
    this.sep = false;
    this.oct = false;
    this.nov = false;
    this.dec = false;
  }

}